<template>
  <div class="styleClass">
    <!-- 添加选择名称 -->
    <div>
      <!-- 科队选择 -->
      <SingleSelection
        :result="reactiveParams.cost.result"
        :columns="reactiveParams.cost.columns"
        :label="reactiveParams.cost.label"
        :placeholder="'请选择科队'"
        :customFieldName="reactiveParams.cost.customFieldName"
        :required="reactiveParams.cost.required"
        @onConfirm="onConfirmkd"
      />
      <!-- 班级名称 -->
      <SingleSelection
        :result="reactiveParams.team.result"
        :columns="reactiveParams.team.columnsTeam"
        :label="reactiveParams.team.label"
        :placeholder="'请选择班组'"
        :customFieldName="reactiveParams.team.customFieldName"
        :required="reactiveParams.team.required"
        @onConfirm="onConfirmbz"
      />
      <!-- 执行班次 -->
      <SingleSelection
        :result="reactiveParams.ExecutiveShift.result"
        :columns="reactiveParams.ExecutiveShift.columnsClasses"
        :label="reactiveParams.ExecutiveShift.label"
        :placeholder="'请选择执行班次'"
        :customFieldName="reactiveParams.ExecutiveShift.customFieldName"
        :required="reactiveParams.ExecutiveShift.required"
        @onConfirm="onConfirmzx"
      />
      <!-- 岗位模式 -->
      <SingleSelection
        :result="reactiveParams.PostMode.result"
        :columns="reactiveParams.PostMode.columnsPost"
        :label="reactiveParams.PostMode.label"
        :placeholder="'请选择岗位名称'"
        :customFieldName="reactiveParams.PostMode.customFieldName"
        :required="reactiveParams.PostMode.required"
        @onConfirm="onConfirmgw"
      />
    </div>
    <!-- 岗位图片 -->
    <div>
      <van-field name="uploader" label="文件上传">
        <template #input>
          <van-uploader v-model="reactiveParams.fileld" :max-count="1" />
        </template>
      </van-field>
    </div>
    <!-- 提交 -->
    <div style="width: 90%; margin: 0 auto; margin-top: 1rem">
      <van-button
        @click="submit"
        type="primary"
        block
        style="border-radius: 0.2rem"
      >
        提交
      </van-button>
    </div>
  </div>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
    onMounted,
  } from "vue";
  import SingleSelection from "@/components/AllScreening/SingleSelection";
  import Datetimesy from "@/components/componentsJs/time";
  import { Toast } from "vant";
  import { useRoute, useRouter } from "vue-router";
  export default {
    components: {
      SingleSelection,
    },

    setup() {
      const { proxy } = getCurrentInstance();
      const route = useRoute();
      const router = useRouter();
      const reactiveParams = reactive({
        fileld: [],
        // 科队选择
        cost: {
          columns: [],
          label: "科队选择",
          customFieldName: {
            text: "nametext",
          },
          required: true,
          result: "",
        },
        // 班级名称
        team: {
          columnsTeam: [],
          label: "班组选择",
          customFieldName: {
            text: "nametext",
          },
          required: true,
          result: "",
        },
        // 执行班次
        ExecutiveShift: {
          columnsClasses: [],
          label: "执行班次",
          customFieldName: {
            text: "nametext",
          },
          required: true,
          result: "",
        },
        // 岗位模式
        PostMode: {
          columnsPost: [],
          label: "岗位模式",
          customFieldName: { text: "name" },
          required: true,
          result: "",
        },
        // 执行班次的ID
        postValue: "",
        form: {
          companyId: "",
          departmentId: sessionStorage.getItem("organizeId"),
          flight: "",
          flightId: "",
          groupId: "",
          picture: "",
          pictureId: "",
          stationmode: "",
          workdate: Datetimesy.Datetimesy(),
        },
      });
      if (route.query.item) {
        reactiveParams.form = JSON.parse(route.query.item);
        console.log(reactiveParams.form, "form信息");
        reactiveParams.team.result = reactiveParams.form.groupName;
        reactiveParams.PostMode.result = reactiveParams.form.modeName;
        reactiveParams.ExecutiveShift.result = reactiveParams.form.flight;
        if (reactiveParams.form.picture) {
          let object = {
            url: reactiveParams.form.picture,
          };
          reactiveParams.fileld.push(object);
        }
      }

      // list 获取队伍方法
      const list = async () => {
        let listArray = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getLaCompanysList
        );
        reactiveParams.cost.columns = listArray.data.data;
        reactiveParams.cost.columns.forEach((item) => {
          item.nametext = `${item.name}|${item.pyjm}`;
        });
        if (route.query.item) {
          let arras = reactiveParams.cost.columns.filter((item) => {
            return item.id == reactiveParams.form.companyId;
          });
          reactiveParams.cost.result = arras[0].nametext;
        }
      };
      // 确认
      function onConfirmkd(e) {
        console.log(e);
        reactiveParams.cost.result = e.nametext;
        reactiveParams.form.companyId = e.id;
        teamList(e.id);
      }

      // 获取班组
      const teamList = async (id) => {
        let listArray = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getLaGroupslist,
          { companyId: id }
        );
        reactiveParams.team.columnsTeam = listArray.data.data;
        reactiveParams.team.columnsTeam.forEach((item) => {
          item.nametext = `${item.name}|${item.pyjm}`;
        });
      };

      function onConfirmbz(e) {
        console.log(e);
        reactiveParams.team.result = e.nametext;
        reactiveParams.form.groupId = e.id;
        executeList(e.id);
      }

      //获取执行班次的数据
      const executeList = async (id) => {
        let listArray = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getGroupFlight,
          { Id: id }
        );
        reactiveParams.ExecutiveShift.columnsClasses =
          listArray.data.data.flight;

        reactiveParams.form.flightId = listArray.data.data.flightId;
      };

      function onConfirmzx(e) {
        console.log(e);
        reactiveParams.ExecutiveShift.result = e;
        reactiveParams.form.flight = e;
      }

      function onConfirmgw(e) {
        console.log(e);
        reactiveParams.PostMode.result = e.name;
        reactiveParams.form.stationmode = e.value;
      }

      //提交方法
      const submit = async () => {
        // route.query.item
        // 判断是否为空
        if (reactiveParams.form.companyId == "") {
          Toast("请选择科队");
          return;
        } else if (reactiveParams.form.groupId == "") {
          Toast("请选择班组");
          return;
        } else if (reactiveParams.form.stationmode == "") {
          Toast("请选择岗位模式");
          return;
        } else if (reactiveParams.form.flight == "") {
          Toast("请选择执行班次");
          return;
        }
        // 图片上传接口
        if (reactiveParams.fileld.length > 0) {
          const formData = new FormData(); // 声明一个FormData对象
          console.log(reactiveParams.fileld[0]);
          formData.append("file", reactiveParams.fileld[0].file);
          formData.append("modelType", "sys");
          let listArray = await proxy.$Api.post(
            proxy.$img.postoneFileUpload,
            formData
          );
          reactiveParams.form.picture = listArray.data.data.path;
          reactiveParams.form.pictureId = listArray.data.data.id;
        }
        // 提交添加数据
        let listArray = await proxy.$Api.post(
          proxy.$laDaysGroupsApi.postLaDaysGroups,
          reactiveParams.form
        );
        console.log(listArray);
        if (listArray.data.code == 200) {
          Toast(listArray.data.msg);
          router.go(-1);
        } else {
          Toast(listArray.data.msg);
          router.go(-1);
        }
      };
      onMounted(() => {
        list();
        reactiveParams.PostMode.columnsPost = JSON.parse(
          localStorage.getItem("dictionaries")
        ).postPattern;
        console.log(reactiveParams.PostMode.columnsPost);
      });
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        onConfirmkd,
        onConfirmbz,
        onConfirmzx,
        onConfirmgw,
        teamList,
        executeList,
        submit,
        list,
      };
    },
  };
</script>
<style scoped>
  .styleClass {
    height: 100vh;
    background-color: #fff;
  }
</style>
